import React from 'react';
import Head from 'next/head';
import { makeStore } from 'reducers/store';
import { GraphQLAPI } from 'utils/RTKGraphQL';
import { formatDefaultDate } from 'utils/misc';
import { getLevels } from 'controllers/SkillController';
import MainArticle from 'features/MainArticle';

export async function getStaticProps() {
  const store = makeStore();
  const state = store.getState();

  const showRead = state.settings.showRead;
  const skills = state.settings.skill;
  const date = state.date;
  const hsks = getLevels(skills);
  const categories = state.settings.cats;
  const profile = state.profile;
  const userId = profile ? profile.id : null;

  // Fetch taxons
  const taxonsResult = await store.dispatch(
    GraphQLAPI.endpoints.getTaxons.initiate()
  );

  const taxons = taxonsResult.data;

  if (!taxons) {
    return {
      notFound: true,
    };
  }

  // Fetch recent articles for each taxon
  const recentArticlesPromises = taxons.map((taxon) =>
    store.dispatch(
      GraphQLAPI.endpoints.getCollectionsByTaxon.initiate({
        taxonId: taxon.id,
        limit: 15,
        ordering: '-date',
        published: true,
        displaySkill: hsks,
        fillToLimit: true,
        userId: userId,
        showRead: showRead,
        start: formatDefaultDate(date.start_date),
        end: formatDefaultDate(date.end_date),
      })
    )
  );

  const recentArticlesResults = await Promise.all(recentArticlesPromises);
  const initialArticlesData = recentArticlesResults
    .map((result, index) => ({
      data: result.data,
      taxon: taxons[index],
    }))
    .filter((article) => article.data !== undefined); // Add this filter

  return {
    props: {
      initialArticlesData,
      showRead,
      hsks,
      categories,
      userId,
      startDate: formatDefaultDate(date.start_date),
      endDate: formatDefaultDate(date.end_date),
    },
    revalidate: 21600, // Revalidate every 6 hours
  };
}

const MainPage = (props) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Pandaist is a Chinese graded reader app that makes learning Chinese fun. With engaging news, stories and audio narration, Pandaist helps you learn Chinese"
        />
        <title>Pandaist | Learn Chinese by Reading</title>
        <link rel="canonical" href="https://pandaist.com" />
        <meta
          name="twitter:description"
          content="Pandaist is a Chinese graded reader app that makes learning Chinese fun. With engaging news, stories and audio narration, Pandaist helps you learn Chinese"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="Pandaist | Learn Chinese by Reading"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Pandaist | Learn Chinese by Reading"
        />
        <meta
          property="og:description"
          content="Pandaist is a Chinese graded reader app that makes learning Chinese fun. With engaging news, stories and audio narration, Pandaist helps you learn Chinese"
        />
        <meta property="og:url" content="https://pandaist.com/" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Pandaist | Learn Chinese by Reading"
        />
        <meta
          property="og:image"
          content={'https://cdn.pandaist.com/main/images/bigpanda.png'}
        />
        <meta
          name="twitter:image"
          content={'https://cdn.pandaist.com/main/images/bigpanda.png'}
        />
      </Head>
      <MainArticle {...props} />
    </>
  );
};

export default MainPage;
